@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1r319w');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1r319w#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?1r319w') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?1r319w') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?1r319w##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-eye-slash {
  &:before {
    content: $icon-eye-slash; 
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart; 
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-unsorted {
  &:before {
    content: $icon-unsorted; 
  }
}
.icon-sort-desc {
  &:before {
    content: $icon-sort-desc; 
  }
}
.icon-sort-down {
  &:before {
    content: $icon-sort-down; 
  }
}
.icon-sort-asc {
  &:before {
    content: $icon-sort-asc; 
  }
}
.icon-sort-up {
  &:before {
    content: $icon-sort-up; 
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus; 
  }
}
.icon-user-times {
  &:before {
    content: $icon-user-times; 
  }
}
.icon-user-circle {
  &:before {
    content: $icon-user-circle; 
  }
}
.icon-user-circle-o {
  &:before {
    content: $icon-user-circle-o; 
  }
}
.icon-cabinet {
  &:before {
    content: $icon-cabinet; 
  }
}
.icon-calendar1 {
  &:before {
    content: $icon-calendar1; 
  }
}
.icon-camera1 {
  &:before {
    content: $icon-camera1; 
  }
}
.icon-microphone {
  &:before {
    content: $icon-microphone; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-uncheck {
  &:before {
    content: $icon-uncheck; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-notice {
  &:before {
    content: $icon-notice; 
  }
}
.icon-cog {
  &:before {
    content: $icon-cog; 
  }
}
.icon-cogs {
  &:before {
    content: $icon-cogs; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-pictures {
  &:before {
    content: $icon-pictures; 
  }
}
.icon-alarmclock {
  &:before {
    content: $icon-alarmclock; 
  }
}
.icon-time {
  &:before {
    content: $icon-time; 
  }
}
.icon-headphones1 {
  &:before {
    content: $icon-headphones1; 
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet; 
  }
}
.icon-checkmark4 {
  &:before {
    content: $icon-checkmark4; 
  }
}
.icon-cancel2 {
  &:before {
    content: $icon-cancel2; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-pie-chart1 {
  &:before {
    content: $icon-pie-chart1; 
  }
}
.icon-chart {
  &:before {
    content: $icon-chart; 
  }
}
.icon-location1 {
  &:before {
    content: $icon-location1; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-basket {
  &:before {
    content: $icon-basket; 
  }
}
.icon-folder1 {
  &:before {
    content: $icon-folder1; 
  }
}
.icon-graduate {
  &:before {
    content: $icon-graduate; 
  }
}
.icon-smiley {
  &:before {
    content: $icon-smiley; 
  }
}
.icon-sad {
  &:before {
    content: $icon-sad; 
  }
}
.icon-medal {
  &:before {
    content: $icon-medal; 
  }
}
.icon-locked {
  &:before {
    content: $icon-locked; 
  }
}
.icon-unlocked1 {
  &:before {
    content: $icon-unlocked1; 
  }
}
.icon-magnifier {
  &:before {
    content: $icon-magnifier; 
  }
}
.icon-zoomin {
  &:before {
    content: $icon-zoomin; 
  }
}
.icon-zoomout {
  &:before {
    content: $icon-zoomout; 
  }
}
.icon-stack {
  &:before {
    content: $icon-stack; 
  }
}
.icon-stack1 {
  &:before {
    content: $icon-stack1; 
  }
}
.icon-stack2 {
  &:before {
    content: $icon-stack2; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-cube {
  &:before {
    content: $icon-cube; 
  }
}
.icon-box {
  &:before {
    content: $icon-box; 
  }
}
.icon-box1 {
  &:before {
    content: $icon-box1; 
  }
}
.icon-grid {
  &:before {
    content: $icon-grid; 
  }
}
.icon-tools {
  &:before {
    content: $icon-tools; 
  }
}
.icon-chats {
  &:before {
    content: $icon-chats; 
  }
}
.icon-comments {
  &:before {
    content: $icon-comments; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-volume {
  &:before {
    content: $icon-volume; 
  }
}
.icon-loud {
  &:before {
    content: $icon-loud; 
  }
}
.icon-mute {
  &:before {
    content: $icon-mute; 
  }
}
.icon-equalizer1 {
  &:before {
    content: $icon-equalizer1; 
  }
}
.icon-resize {
  &:before {
    content: $icon-resize; 
  }
}
.icon-resize1 {
  &:before {
    content: $icon-resize1; 
  }
}
.icon-library1 {
  &:before {
    content: $icon-library1; 
  }
}
.icon-auction {
  &:before {
    content: $icon-auction; 
  }
}
.icon-justice {
  &:before {
    content: $icon-justice; 
  }
}
.icon-stats {
  &:before {
    content: $icon-stats; 
  }
}
.icon-hourglass {
  &:before {
    content: $icon-hourglass; 
  }
}
.icon-pencil1 {
  &:before {
    content: $icon-pencil1; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-scissors {
  &:before {
    content: $icon-scissors; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-reply {
  &:before {
    content: $icon-reply; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-addressbook {
  &:before {
    content: $icon-addressbook; 
  }
}
.icon-addressbook1 {
  &:before {
    content: $icon-addressbook1; 
  }
}
.icon-safe {
  &:before {
    content: $icon-safe; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-envelope-open {
  &:before {
    content: $icon-envelope-open; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-book2 {
  &:before {
    content: $icon-book2; 
  }
}
.icon-barcode {
  &:before {
    content: $icon-barcode; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-collapse {
  &:before {
    content: $icon-collapse; 
  }
}
.icon-popout {
  &:before {
    content: $icon-popout; 
  }
}
.icon-popin {
  &:before {
    content: $icon-popin; 
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket; 
  }
}
.icon-blocked1 {
  &:before {
    content: $icon-blocked1; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-printer1 {
  &:before {
    content: $icon-printer1; 
  }
}
.icon-pictures1 {
  &:before {
    content: $icon-pictures1; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-shipping {
  &:before {
    content: $icon-shipping; 
  }
}
.icon-tags {
  &:before {
    content: $icon-tags; 
  }
}
.icon-heart-plus {
  &:before {
    content: $icon-heart-plus; 
  }
}
.icon-heart-minus {
  &:before {
    content: $icon-heart-minus; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-ruler {
  &:before {
    content: $icon-ruler; 
  }
}
.icon-apparel {
  &:before {
    content: $icon-apparel; 
  }
}
.icon-book-reference {
  &:before {
    content: $icon-book-reference; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-factory {
  &:before {
    content: $icon-factory; 
  }
}
.icon-gift1 {
  &:before {
    content: $icon-gift1; 
  }
}
.icon-load-balancer {
  &:before {
    content: $icon-load-balancer; 
  }
}
.icon-map1 {
  &:before {
    content: $icon-map1; 
  }
}
.icon-notification1 {
  &:before {
    content: $icon-notification1; 
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications; 
  }
}
.icon-notifications-outline {
  &:before {
    content: $icon-notifications-outline; 
  }
}
.icon-stargrade {
  &:before {
    content: $icon-stargrade; 
  }
}
.icon-star_half {
  &:before {
    content: $icon-star_half; 
  }
}
.icon-star_outline {
  &:before {
    content: $icon-star_outline; 
  }
}
.icon-add_shopping_cart {
  &:before {
    content: $icon-add_shopping_cart; 
  }
}
.icon-done {
  &:before {
    content: $icon-done; 
  }
}
.icon-done_all {
  &:before {
    content: $icon-done_all; 
  }
}
.icon-record_voice_over {
  &:before {
    content: $icon-record_voice_over; 
  }
}
.icon-remove_shopping_cart {
  &:before {
    content: $icon-remove_shopping_cart; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-office {
  &:before {
    content: $icon-office; 
  }
}
.icon-newspaper {
  &:before {
    content: $icon-newspaper; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-images {
  &:before {
    content: $icon-images; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones; 
  }
}
.icon-music {
  &:before {
    content: $icon-music; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-film {
  &:before {
    content: $icon-film; 
  }
}
.icon-connection {
  &:before {
    content: $icon-connection; 
  }
}
.icon-podcast {
  &:before {
    content: $icon-podcast; 
  }
}
.icon-book1 {
  &:before {
    content: $icon-book1; 
  }
}
.icon-books {
  &:before {
    content: $icon-books; 
  }
}
.icon-library {
  &:before {
    content: $icon-library; 
  }
}
.icon-file-picture {
  &:before {
    content: $icon-file-picture; 
  }
}
.icon-file-music {
  &:before {
    content: $icon-file-music; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-paste {
  &:before {
    content: $icon-paste; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-folder-plus {
  &:before {
    content: $icon-folder-plus; 
  }
}
.icon-folder-minus {
  &:before {
    content: $icon-folder-minus; 
  }
}
.icon-folder-download {
  &:before {
    content: $icon-folder-download; 
  }
}
.icon-folder-upload {
  &:before {
    content: $icon-folder-upload; 
  }
}
.icon-price-tag {
  &:before {
    content: $icon-price-tag; 
  }
}
.icon-price-tags {
  &:before {
    content: $icon-price-tags; 
  }
}
.icon-qrcode1 {
  &:before {
    content: $icon-qrcode1; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-coin-dollar {
  &:before {
    content: $icon-coin-dollar; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-address-book {
  &:before {
    content: $icon-address-book; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-map2 {
  &:before {
    content: $icon-map2; 
  }
}
.icon-history {
  &:before {
    content: $icon-history; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-clock2 {
  &:before {
    content: $icon-clock2; 
  }
}
.icon-alarm {
  &:before {
    content: $icon-alarm; 
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-printer {
  &:before {
    content: $icon-printer; 
  }
}
.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-redo {
  &:before {
    content: $icon-redo; 
  }
}
.icon-spinner2 {
  &:before {
    content: $icon-spinner2; 
  }
}
.icon-spinner11 {
  &:before {
    content: $icon-spinner11; 
  }
}
.icon-binoculars {
  &:before {
    content: $icon-binoculars; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge; 
  }
}
.icon-shrink {
  &:before {
    content: $icon-shrink; 
  }
}
.icon-hammer {
  &:before {
    content: $icon-hammer; 
  }
}
.icon-magic-wand {
  &:before {
    content: $icon-magic-wand; 
  }
}
.icon-stats-dots {
  &:before {
    content: $icon-stats-dots; 
  }
}
.icon-gift {
  &:before {
    content: $icon-gift; 
  }
}
.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase; 
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-road {
  &:before {
    content: $icon-road; 
  }
}
.icon-target {
  &:before {
    content: $icon-target; 
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard; 
  }
}
.icon-list-numbered {
  &:before {
    content: $icon-list-numbered; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-tree {
  &:before {
    content: $icon-tree; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; 
  }
}
.icon-cloud-check {
  &:before {
    content: $icon-cloud-check; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment; 
  }
}
.icon-bookmark1 {
  &:before {
    content: $icon-bookmark1; 
  }
}
.icon-bookmarks {
  &:before {
    content: $icon-bookmarks; 
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty; 
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half; 
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-info1 {
  &:before {
    content: $icon-info1; 
  }
}
.icon-cancel-circle {
  &:before {
    content: $icon-cancel-circle; 
  }
}
.icon-blocked {
  &:before {
    content: $icon-blocked; 
  }
}
.icon-enter {
  &:before {
    content: $icon-enter; 
  }
}
.icon-exit {
  &:before {
    content: $icon-exit; 
  }
}
.icon-circle-up {
  &:before {
    content: $icon-circle-up; 
  }
}
.icon-circle-right {
  &:before {
    content: $icon-circle-right; 
  }
}
.icon-circle-down {
  &:before {
    content: $icon-circle-down; 
  }
}
.icon-circle-left {
  &:before {
    content: $icon-circle-left; 
  }
}
.icon-tab {
  &:before {
    content: $icon-tab; 
  }
}
.icon-move-up {
  &:before {
    content: $icon-move-up; 
  }
}
.icon-move-down {
  &:before {
    content: $icon-move-down; 
  }
}
.icon-sort-alpha-asc {
  &:before {
    content: $icon-sort-alpha-asc; 
  }
}
.icon-sort-alpha-desc {
  &:before {
    content: $icon-sort-alpha-desc; 
  }
}
.icon-sort-numeric-asc {
  &:before {
    content: $icon-sort-numeric-asc; 
  }
}
.icon-sort-numberic-desc {
  &:before {
    content: $icon-sort-numberic-desc; 
  }
}
.icon-sort-amount-asc {
  &:before {
    content: $icon-sort-amount-asc; 
  }
}
.icon-sort-amount-desc {
  &:before {
    content: $icon-sort-amount-desc; 
  }
}
.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked; 
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked; 
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked; 
  }
}
.icon-radio-checked2 {
  &:before {
    content: $icon-radio-checked2; 
  }
}
.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked; 
  }
}
.icon-table1 {
  &:before {
    content: $icon-table1; 
  }
}
.icon-table2 {
  &:before {
    content: $icon-table2; 
  }
}
.icon-insert-template {
  &:before {
    content: $icon-insert-template; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}
.icon-file-word {
  &:before {
    content: $icon-file-word; 
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel; 
  }
}
.icon-libreoffice {
  &:before {
    content: $icon-libreoffice; 
  }
}

