body {
 // text-shadow: 0px 1px 2px #50333340;
 // background: url(/images/paper_fibers.png);
}

::placeholder,::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: green;
  opacity: 1; /* Firefox */
}

.required input,
.required select,
.required {
  background-color: cornsilk !important;
  color: #000 !important;
}

.nivel1 {
  margin-left: 15px;
}

.nivel2 {
  margin-left: 30px;
}

.pagination>label {
  margin: 4px 8px 2px 8px;
}




// General style overrides and custom classes
body {
  margin: 0;
}

.my-widget {
  color: var(--danger);
}




.table_container {
  width: 100%;
  overflow-x: auto;
  padding-top: 10px;
}


div.inactive,
tr.inactive>td {
  filter: saturate(0) opacity(0.8) ;
  /*
  background-color: #a21d1d;
  color: #fff;
  */
}

div.active {
    border: solid #4e98bb 1px;
}

tr.active {
  /*
  filter: hue-rotate(208deg) invert(1);
  color: #fff;
  */
  
  >td[rowspan=""] {
    background-color: #4e98bb;
  }
  // border: solid red 10px !important;

}


tr.active>th {
  /*
  filter: hue-rotate(208deg) invert(1);
  color: #fff;
  */
  background-color:#4e98bb;
}
/*
body.noTextSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*/

.disabled {
   opacity: 0.6 !important;
  filter: grayscale(100%);
}


div {
  // transition: all 0.15s linear !important;
}

.vs__dropdown-menu,
.dropdown-menu {
  min-width: 300px !important;
  max-width: 90vw !important;

}












/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #5f5f5d #cccccc;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #cccccc;
}

*::-webkit-scrollbar-thumb {
  background-color: #5f5f5d;
  border-radius: 20px;
  border: 3px solid #cccccc;
}


.vue-sticky-el.top-sticky {
    margin-top: 48px !important;
    z-index: 1 !important;
}

.vue-sticky-el.top-sticky>* {
    background: #fff;
    padding-top: 6px;
    margin-left: -10px;
    margin-right: -10px;
}

.form-group,.btn-group{
  margin-bottom: 0 !important;
}

.br1{
  width: 100%;
    height: 10px;
    display: block;
    content: " ";
}