 //$fontSize:12px;
.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}


 .bg-primary {
   background:  3a4b91;
   /* background: linear-gradient(270deg, #3490dc, #6574cd);
   background-size: 400% 400%;

   -webkit-animation: AnimationName 30s ease infinite;
   -moz-animation: AnimationName 30s ease infinite;
   animation: AnimationName 30s ease infinite;

   @-webkit-keyframes AnimationName {
     0% {
       background-position: 0% 50%
     }

     50% {
       background-position: 100% 50%
     }

     100% {
       background-position: 0% 50%
     }
   }

   @-moz-keyframes AnimationName {
     0% {
       background-position: 0% 50%
     }

     50% {
       background-position: 100% 50%
     }

     100% {
       background-position: 0% 50%
     }
   }

   @keyframes AnimationName {
     0% {
       background-position: 0% 50%
     }

     50% {
       background-position: 100% 50%
     }

     100% {
       background-position: 0% 50%
     }
   }
   */
 }

 .dropdown>.btn {

   border: none !important;
   box-shadow: none !important;
   padding-left: 4px !important;
   padding-right: 4px !important;
 }


 body {
   //font-family: tahoma;
   // font-size: $fontSize !important;
   font-weight: 400;
   //line-height: 1 !important;
   color:
     #212529;
   text-align: left;



   background-color: #b7b7c5;
opacity: 0.8;
background-image:  radial-gradient(#d9d9df 0.2px, transparent 0.2px), radial-gradient(#d9d9df 0.2px, #b7b7c5 0.2px);
background-size: 8px 8px;
background-position: 0 0,4px 4px;


 }

 .modal-header {
     padding: 0.5rem 1rem 0.2rem 1rem !important;

    }
    .modal-body {
        padding:0;
    }

 .btn,
 .form-control,
 .v-select,
 .custom-select {
    // font-size: $fontSize !important;

   border-color: #00000080 !important;
   height: auto !important;
 }

 .custom-control.custom-checkbox.b-custom-control-sm {

   margin-top: 4px;

 }

 .dropdown-toggle {
   border: solid 1px #00000080 !important;
   padding: 0 !important;
   // height:calc(1.2em + 0.36rem + 2px);
   margin-bottom: 2px;
 }

 .dropdown-toggle>div>input {
   border: none !important;
   margin: 0 !important;
 }

 .custom-radio {

   margin-bottom: 2px;

 }

 .dropdown.v-select.single>div {

   //.dropdown.v-select.single>div>.vs__selected-options>.selected-tag{
   // white-space: nowrap;
   &>* {
     min-height: calc(1.2em + 0.36rem + 2px);
   }

   &>.vs__selected-options>input {
     position: absolute;
     height: calc(1.2em + 0.36rem + 2px);
     width: 100%;
   }

   .open-indicator {

     position: absolute;
     right: 2px;
     top: 9px;

   }

   .clear {

     margin-right: 10px !important;
     margin-top: 2px;

   }

   .vs__actions {
     position: absolute;
     right: 0;
     top: -4px;
     z-index: 1;
   }

   .selected-tag {

     margin-top: 0 !important;
     z-index: 9;
   }
 }

 #app>div.wrapper>#content {

   .mx-datepicker,
   .custom-select,
   select,
   input,
   .form-control {
     height: calc(1em + 0.75rem + 2px);
     // max-height: calc(1em + 0.75rem + 2px) !important;
     //line-height: 1 !important;
     max-width: 100% !important;
     //font-size: $fontSize !important;
   }

 }

 .v-select .vs__selected-options {
   padding: 0 !important;
 }



 .modal-content {
   box-shadow: 0px 0px 10px rgb(0, 0, 0) !important;
   border-radius: 0.4rem;
   
   /*transition: box-shadow 0.3s ease-in-out !important; */
 }
 .modal-body{
  padding:5px 20px;
 }
*,
 div {
  //  transition: all 0.2s ease-in-out !important;
 }



 .btn
 /*
 ,
 .btn-group
 */

 {
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.6) !important;
   border-radius: 0.4rem;

 }

 .btn-group:not(:first-of-type) {
   margin-left: 1em;
 }

 .btn-group .btn {
   box-shadow: 0 0 1px rgba(0, 0, 0, 0.5) !important;
   border-radius: 0.4rem;
 }

 #content>div {
   padding-left: 10px;
   padding-right: 10px;
 }

 h1,
 h2,
 h3 {
   //line-height: 1 !important;
   padding: 5px !important;
 }

 .btn-group {
   margin-bottom: 8px;
 }



.container {
  max-width: 100%;
}




.vue-sticky-el.top-sticky:not(:hover):before {
    display:block;
       background:#ccc;
       content:"...";


           height:16px;
       border-radius: 0px 0px 10px 10px;
   text-align: center;
       line-height: 0px;
       font-size:24px;
           opacity:0.7;
   }


.elTable >thead{


    position: sticky;
    top: -10px;
}

.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }




     .vue-sticky-el.top-sticky:not(:hover)
     {
         max-height: 16px !important;
         overflow: hidden;
     }
     .vue-sticky-el.top-sticky{
          border-radius: 0px 0px 10px 10px;
          /*margin:0 5px 10px 5px;*/
         outline: solid 1px #ccc;
         box-shadow: 0 0 4px;
         z-index: 999 !important;
     }

     thead.sticky {
      top: -15px;
  }
  .table_container{
      border: solid 1px;
      /* // border-radius: 10px !important; */
  }
     .wrapper.menuFixed {


         .table_container{
          max-height: calc( 100vh - 161px );
      }
  /*
      th{
          position: sticky !important;
      }
      */
  }










  .dd_escalafon 
.dropdown-menu{
    min-width: 450px !important;
    min-height:calc(100vh - 100px);
    box-shadow: 0 0  12px;
}
.horariowrapper0{
    min-height: 250px;
    max-height: 250px;
}
.form-group .date-picke{
    padding:0;
}




.tags-view-item
{
   height: 75px;
   background: blue;
   -webkit-transform: skew(-20deg); 
   -moz-transform: skew(-20deg); 
   -o-transform: skew(-20deg);
   transform: skew(-20deg);
    border-radius: 0 !important;
    margin: -5px;
} 


.vue-sticky-el{
  background: #fff;
  z-index:99; 
  min-height: 34px;
  /*
  max-height:80px !important;
    overflow-y: auto;
    overflow-x: hidden;
*/
}
.vue-sticky-el.top-sticky {
  max-height: 300px !important;
}
.input-group-prepend>button {
  width: 100%;
}

.vue-monthly-picker .month-picker-wrapper {
  min-width: 60px;
  max-width: 90px;
}
#data>div>h4,
#data>h4 {
   display:none;
}


element.style {
  display: block;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.alert-danger {
  background-color: #f9d6d5aa;
}
.alert-warning {
  background-color: #fffbdbaa;
}
.alert-success {
  background-color: #d7f3e3aa;
}




.bioCC {
  height: 170px;
  overflow: auto;
  margin-bottom: 5px;
  border: solid 1px #aaa;
  border-radius: 4px;
}
.bioc{
  text-align: left;
  width:100%;
}
.bioc .bio{
  float:right;
}
.bio>* {
  width: 16px !important;
  height: auto !important;
  display: inline-block;
  border: none;
}
.bio>img.disabled {
  opacity: 0.05 !important;
}
button.bioc>span {
  display:block;
width:100%;
}
button.bioc.btn {
  border: none;
  box-shadow: none !important;
}