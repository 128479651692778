$enable-shadows: false;
$enable-gradients: false;
$enable-rounded: true;
$enable-transitions: false;
$enable-gradients: false;


$grid-breakpoints: (xs: 0,
  sm: 456px,
  md: 789px,
  lg: 999px,
  xl: 1234px);
$bv-enable-table-stacked: false;




$spacer: 0.75rem;
$font-size-base:0.55rem;
$line-height-base:1.2;
$line-height-lg:1.2;
$line-height-sm:1.2;
$headings-line-height: 1.2;

$input-btn-padding-y: .2rem;
$input-btn-padding-x: .5rem;

$input-btn-padding-y-sm: ($input-btn-padding-y*0.9);
$input-btn-padding-x-sm: ($input-btn-padding-x*0.9);

$input-btn-padding-y-lg: ($input-btn-padding-y*1.2);
$input-btn-padding-x-lg: ($input-btn-padding-x*1.2);

$nav-link-padding-y: .15rem;
$nav-link-padding-x: .5rem;

$navbar-nav-link-padding-x: .5rem;
$navbar-nav-link-padding-y: .15rem;

$navbar-toggler-padding-y: .15rem;
$navbar-toggler-padding-x: .5rem;


$jumbotron-padding: 1rem;
$card-spacer-y: .5rem;
$card-spacer-x: 1rem;
$card-img-overlay-padding: 0.2rem;

$tooltip-padding-y: .15rem !default;
$tooltip-padding-x: .25rem !default;



$toast-padding-x: .5rem !default;
$toast-padding-y: .15rem !default;


$list-group-item-padding-y: $input-btn-padding-y;
$list-group-item-padding-x: $input-btn-padding-x;

$pagination-padding-y: $input-btn-padding-y;
$pagination-padding-x: $input-btn-padding-x;
$pagination-padding-y-sm: $input-btn-padding-y-sm;
$pagination-padding-x-sm: $input-btn-padding-x-sm;
$pagination-padding-y-lg: $input-btn-padding-y-lg;
$pagination-padding-x-lg: $input-btn-padding-x-lg;
$pagination-line-height: $line-height-base;


$breadcrumb-padding-y: $input-btn-padding-y;
$breadcrumb-padding-x: $input-btn-padding-x;
$breadcrumb-item-padding: 1rem;

$popover-header-padding-y: $input-btn-padding-y;
$popover-header-padding-x: $input-btn-padding-x;


$modal-inner-padding:.5rem;
$modal-dialog-margin-y-sm-up: 1.2rem;

$modal-header-padding-y: .5rem;
$modal-header-padding-x: .5rem;

$table-cell-padding: .5em;

/*
$modal-xl:                          //1140px !default;
$modal-lg:                          //800px !default;
$modal-md:                          //500px !default;
$modal-sm:                          //300px !default;
*/

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .15s ease-out !default;

$alert-padding-y: .5rem !default;
$alert-padding-x: 1rem !default;
$alert-margin-bottom: .5rem !default;

$print-page-size: a4;



// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
//$primary: #202f41;
//$secondary: #436296;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);
// Include Bootstrap and BootstrapVue SCSS files
//@import '~bootstrap/scss/bootstrap';
//@import '~bootstrap-vue/src/index.scss'; // Body
$body-bg: #f8fafc;
/*
// Typography
$font-family-sans-serif: 'Tahoma',
sans-serif;
$s-base: 1rem;
$line-height-base: 1;
*/

// Colors 
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;




// Include Bootstrap and BootstrapVue SCSS files
/*
*/
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';


.btn.btn-basic,
.btn-basic {
  @include button-variant(#eee, #ddd, #ddd, #ddd, #ccc, #aaa);
  color: #000;
}

.btn.btn-morado,
.btn-morado {
  @include button-variant(#874c97, #683a75, #ccabd5, #d6bcdd, #3e2345, #5f356a);

  color: #fff;
}